import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "./boton/ButtonContent";
// import roof from "../../assets/image/roof.gif"

function BloqueHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
    <div className="relative md:pt-[150px] pt-[300px] pb-[120px] md:w-4/5 w-full mx-auto flex md:flex-row flex-col md:p-6 md:mt-16 -mt-16 px-2 ">

      <div className="md:w-1/2 w-[95%] text-start mt-8 ml-4 md:ml-0 text-white  md:text-black py-4 px-2 rounded-md">
        <h1 className="text-start">
          {rpdata?.dbSlogan?.[1].slogan}
        </h1>
        <p className="text-start ">
          {rpdata?.dbValues?.[0].description}
        </p>
        <ButtonContent btnStyle="three" />
      </div>




      <div className="md:w-1/2 w-full p-4 ">
        <div className="md:bg-[#e17a5b] md:w-full md:h-[800px] w-full md:mt-[-270px] md:mb-[-220px] md:ml-[300px]">
          <div className="abolute w-full h-full md:flex justify-center items-center md:pt-[100px] md:-ml-64 ml-0">
            <img
              src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Roofing%2Froofing-process-compressor.gif?alt=media&token=af068cec-c3a0-4a27-81e5-8e8851517fa2"}
              alt="Not Found"
              className="md:h-[400px] h-[350px] w-[500px]"
            />
          </div>
        </div>
        <div className="md:w-[200px] w-full bg-[#e17a5b] block md:hidden h-[150vh] absolute top-36 right-0 -z-10">
          
        </div>
      </div>
    </div>
    </>
  );
}

export default BloqueHome;
